import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Header } from "../components/collections/Header";
import { LogoPanel } from "../panels/logoPanel";
import { Footer } from "../components/collections/Footer";
import { Builder } from "../components/collections/Builder";
import { CarouselPannel } from "../panels/carouselPannel";

const CaseStudy = ({ data: { wordpressWpCaseStudy } }) => {
  const post = wordpressWpCaseStudy;

  const header = {
    textColor: post.acf.header_text_color,
    isParallax: post.acf.header_parallax,
    image: {
      ...post.acf.header_image_bg,
    },

    backgroundType: post.acf.header_background,
    title: post.title,
  };

  if (
    post.acf.header_image_bg &&
    post.acf.header_image_bg.localFile &&
    post.acf.header_image_bg.localFile.childImageSharp
  ) {
    header.image.src =
      post.acf.header_image_bg.localFile.childImageSharp.original.src;
  }

  if (post.acf.header_video) {
    header.video = post.acf.header_video.localFile.url;
  }

  const { content_builder_case_study } = post.acf;

  return (
    <Layout customSEO>
      <Header {...header} />
      <Builder content={content_builder_case_study} />
      <CarouselPannel />
      <LogoPanel />
      <Footer />
    </Layout>
  );
};

export default CaseStudy;

export const pageQuery = graphql`
  query caseStudyBySlug($uid: String!) {
    wordpressWpCaseStudy(slug: { eq: $uid }) {
      featured_media {
        source_url
        alt_text
        title
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      title
      template
      acf {
        header_parallax
        header_image_bg {
          alt_text
          source_url
          title
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        header_background
        header_video {
          localFile {
            absolutePath
            url
          }
        }
        header_text_colour
        content_builder_case_study {
          __typename
          ... on WordPressAcf_grid_content {
            heading
            image {
              alt_text
              source_url
              title
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            text
            text_width
            layout
          }
          ... on WordPressAcf_text_block {
            id
            text
            heading
            text_size
          }
          ... on WordPressAcf_parallax {
            choose_fixed
            choose_layers
            image_bg {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            image_bottom
            image_top
          }
        }
      }
    }
  }
`;
